#b_navbar {
    /* height: auto; */
    /* min-height: 55px; */
    /* box-sizing: border-box; */
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    /* width: auto; */
    /* overflow: hidden; */
    /* flex-shrink: 0; */
    background: #eff3f2;
    color: #44b78b;
    /* margin-bottom: 20px; */
    border-bottom: 10px solid #44b78b;
}

.bright_green {
    background: #44b78b;
}

#user-tools a,
#b_navbar .navbar-brand {
    color: white;
}

#b_navbar .navbar-brand {
    padding: 10px 0px;
}

#user-tools {
    float: right;
    padding: 0;
    margin: 0 0 0 20px;
    font-weight: 300;
    font-size: 11px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: right;
}


.vendor {
    display: flex;
    align-items: center;
}

.vendor_name {
    margin: 0;
}

.vendor_image {
    height: 30px;
}

.affiliate_name {
    margin: 0;
}

.affiliate_image {
    height: 50px;
    width: 50px;
}

/* 
.video-responsive {
    height: 200px;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 50%;
    width: 50%;
    position: absolute;
} */


/* .video-responsive {
    float: none;
    clear: both;
    width: 700px;
    position: relative;
    height: 0;
    margin: 0 auto;
} */

.custom-modal {
    width: 100VW;
    height: 100VH;
    max-width: unset;
    margin: 0;
}

.custom-modal .modal-content {
    height: 100%;
}

.animated-logo-on-sticky {
    display: none;
}

.product_affiliate_images{
    height: 40px;
    width: 40px;
    border: #44b78b 2px solid;
}

.p-card-background{
    background: #eff3f2; 
}

.product .card-header {
    border-bottom: 4px solid #44b78b;
}

.time-on-top{
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: none;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* After sticky class added show logo sliding from left */
.product-sticky {
    z-index: 2;
}

.product-sticky .animated-logo-on-sticky {
    display: block;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

.product-sticky > div {
    background-color: #eff3f2 !important;
    border-bottom: 8px solid #44b78b;
}

.top-product-image{
    width: -webkit-fill-available;
}


#user-modal .modal-content{
    max-height: calc(100vh - 35px);
}


@media (max-width: 768px) {
    #product_image{
        height: 131px !important;
    }
  }

  @media (max-width: 480px) {
    /* For mobile phones: */
    #logo{
        height: 60px;
    }
    #hide-mobile{
        display: none !important;
    }
    #home-sort-buttons{
        justify-content: space-between !important;
    }

    #home-sort-buttons-label{
        display: none;
    }

    #home-sort-buttons > div {
        width: 100%;
    }

    #home-sort-buttons button {
        padding: 5px;
        font-size: 17px;
    }

    #product_image{
        height: 223px !important;
    }

    #second-top-bar {
        flex-direction: column;
        align-items: unset !important;
        justify-self: unset !important;
    }
    #user-modal-buttons {
        flex-direction: column-reverse;
    }
  }