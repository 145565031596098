/* First button under home-sort-buttons id */
#home-sort-buttons button:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

/* Last button under home-sort-buttons id */
#home-sort-buttons button:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}